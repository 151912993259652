<template>
  <div>
    <a-tabs default-active-key="1">
      <a-tab-pane key="1">
        <span slot="tab">
          <a-icon type="dollar" theme="twoTone" two-tone-color="#eb2f96" />
          Crédits
        </span>
        <br />
        <br />
        <a-row type="flex">
          <a-col :span="24">
            <ReglementsList
              :loading="loading"
              :pagination="reglementsPagination"
              :data-source="reglements"
              :is-admin="false"
              :somme="sommeReglements"
              @set_pagination="setReglementPagination($event)"
              @refresh_data="getReglementsList($event)"
            ></ReglementsList>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="2">
        <span slot="tab">
          <a-icon type="dollar" theme="twoTone" two-tone-color="#52c41a" />
          Paiements
        </span>
        <a-row type="flex" justify="space-around" class="mg-b mg-t">
          <a-col :xs="20" :md="4">
            <a-button
              class="editable-add-btn margin_btm"
              type="primary"
              @click="handleAdd"
            >
              Nouveau Paiement
            </a-button>
          </a-col>
          <a-col :xs="20" :md="4" v-if="!hasAnyPaiement">
            <a-button
              class="editable-add-btn margin_btm"
              type="primary"
              @click="handlePassifPaiementEditorShow"
            >
              Ajouter paiement passif
            </a-button>
          </a-col>
          <a-col v-if="hasAnyPaiement" :xs="20" :md="6">
          Solde :   <solde-indicator :balance="getUserBalance"/>
          </a-col>
        </a-row>
        <a-row
          v-if="getUserBalance"
          type="flex"
          justify="start"
          class="mg-b pd-l"
        >
          <a-col :xs="20" :md="12">
             <exercices-to-pay-indicator  :balance="getUserBalance" :exercices="getUserExercicesARegler" />
          </a-col>
        </a-row>
        <a-row type="flex">
          <a-col :span="22">
            <ListCotisations
              :loading="loading"
              :cotisations="cotisations"
              :pagination="pagination"
              @edit="handleEdit($event)"
              @delete="handleDelete"
              @delete_file="handleFileDelete"
              @upload_success="handleUploadSuccess"
              @refresh_data="getCotisationList"
              @set_pagination="setPagination($event)"
            ></ListCotisations>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
    <CotisationEditor
      ref="paiementForm"
      :key="cotisationComponentKey"
      :visible="visible"
      :exercices="exercices"
      :type_paiements="type_paiements"
      :modes_reglement="modes"
      :processing="processing"
      :id="edit_id"
      :paiement="selected_paiement"
      @close="handleClose"
      @cancel="handleClose"
      @confirme="handleSubmit"
    />
    <PassifPaiementEditor
      ref="passifPaiementEditor"
      :visible="passifPaiementEditorVisible"
      :exercices="exercices"
      :processing="passifPaiementEditorProcessing"
      @close="handlePassifPaiementEditorClose"
      @submit="handlePassifPaiementEditorSubmit"
    />
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
  import auth from "@/services/auth";
  import moment from "moment";
  import ListCotisations from "@/components/common/cotisation/CotisationList";
  import CotisationEditor from "@/components/common/cotisation/CotisationEditor";
  import ReglementsList from "@/components/common/paiement/ReglementsList";
  import PassifPaiementEditor from "@/components/common/cotisation/PassifPaiementEditor";
  import SoldeIndicator from '@/components/common/cotisation/SoldeIndicator';
  import ExercicesToPayIndicator from '@/components/common/cotisation/ExercicesToPayIndicator';

  export default {
    name: "CotisationsList",
    created() {
      this.resetPagination();
      this.clearUserBalance();
      this.fetchData();
      this.getAddtionalData();
    },
    components: {
      ListCotisations,
      CotisationEditor,
      ReglementsList,
      PassifPaiementEditor,
      SoldeIndicator,
      ExercicesToPayIndicator
    },
  data() {
    return {
      confirmDirty: false,
      visible: false,
      loading: true,
      processing: false,
      passifPaiementEditorVisible: false,
      passifPaiementEditorProcessing: false,
      autoCompleteResult: [],
      sexe: 0,
      newMailComponentKey: 0,
      cotisationComponentKey: 0,
      CALCULE_BALANCE_TIMEOUT: 3 * 1000,
      edit_id: null,
      exercice: "",
      id: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.adherent.adherent,
      cotisations: (state) => state.user_paiement.paiements,
      reglements: (state) => state.adherent_reglement.reglements,
      pagination: (state) => state.user_paiement.pagination,
      modes_reglement: (state) => state.mode_reglement.modes_reglement,
      exercices: (state) => state.exercices.exercices,
      type_paiements: (state) => state.type_paiements.type_paiements,
      solde: (state) => state.user_paiement.solde,
      selected_paiement: (state) => state.user_paiement.user_selected_paiement,
      reglementsPagination: (state) => state.adherent_reglement.pagination,
    }),
    ...mapGetters({
      existingReglment: "existingAdminCotisation",
      sommeReglements: "getUserListPaiementSoldSomme",
      getUserBalance: "getUserBalanceValue",
      getUserExercicesARegler: "getUserBalanceExercicesARegler",
      organisationAllowsAderial: "getOrganisationAllowsAderial",
    }),
    hasAnyPaiement() {
      return this.cotisations.length;
    },
    modes() {
      return this.modes_reglement.filter(m => m.code !== "ELG" || this.organisationAllowsAderial);
    },
  },
  methods: {
    moment,
    handlePassifPaiementEditorClose() {
      this.passifPaiementEditorVisible = false;
    },
    handlePassifPaiementEditorShow() {
       if (!this.isUserAccepter(this.user.etat)) {
        this.$_throwAnError("L'administration n'a pas encore accepté votre demande, vous ne pouvez pas ajouter un paiement.");
        return;
      }
      this.passifPaiementEditorVisible = true;
    },
    getAddtionalData() {
      this.$store.cache.dispatch("fetchExercices");
      this.$store.cache.dispatch("fetchModesReglement");
      this.getTypePaiements();
    },
    getCalculateUserBalance(){
      this.calculateUserBalance(auth.getUserId());
    },
    fetchData() {
      this.startLoading();
      this.$store.cache.dispatch("fetchOrganisation");
      this.$store.cache.dispatch("fetchAdherent").then(() => {
                this.getCotisationList();
                this.getReglementsList();
                this.getCalculateUserBalance();
              });
    },
    showEditor() {
      this.genererateNewPaiementEditorKey();
      this.showModal();
    },
    handleClose() {
      this.closeEditor();
      this.resetSelectedCotisation();
    },
    getCotisationList() {
      this.startLoading();
      this.fetchCotisations({exercice: this.exercice})
              .finally(() => this.stopLoading());
    },
    getReglementsList() {
      this.startLoading();
      this.fetchReglements()
              .finally(() => this.stopLoading());
    },
    handleUploadSuccess() {
      this.startLoading();
      this.getCotisationList()
              .finally(() => this.stopLoading());
    },
    closeEditor() {
      this.hideModal();
      this.stopProcessing();
    },
    handleSubmit() {
      let error = false;
      this.$refs["paiementForm"].form.validateFieldsAndScroll((err, values) => {
        if (err) {
          error = true
          return
        }
        let paiement = {
          ...values,
          'user_id': this.user.id,
          'dt_encaissement': values.dt_encaissement.format("YYYY-MM-DD")
        };

        this.startProcessing();
        if (this.selected_paiement) {
          this.update({
            ...paiement,
            id: this.selected_paiement.id,
          })
                  .then(() => {
                    this.resetAndClean();
                    this.getCotisationList();
                    this.handleUserBalanceCalcul();
                  })
                  .catch(() => this.stopProcessing());
          return;
        }
            this.add(paiement)
              .then((res) => {
                if (res.data.redirect_url != null) 
                  window.open(res.data.redirect_url, '_blank');
                this.resetAndClean();
                this.getCotisationList();
                this.handleUserBalanceCalcul();
              })
              .catch(() => this.stopProcessing());
      });
    },
    handlePassifPaiementEditorSubmit() {
      let error = false;
      this.$refs["passifPaiementEditor"].form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          let paiementPassif = {...values};
          paiementPassif.userId = this.user.id;
          this.startPassifPaiementEditorProcessing();
          this.addPaiementPassif(paiementPassif)
                  .then(() => {
                    this.stopPassifPaiementEditorProcessing();
                    this.handlePassifPaiementEditorClose();
                    this.getCotisationList();
                    this.handleUserBalanceCalcul();
                  }).catch(() => this.stopPassifPaiementEditorProcessing());
        } else {
          error = true;
        }
      });
    },
    resetAndClean() {
      this.resetSelectedCotisation();
      this.$refs["paiementForm"].form.resetFields();
      this.$refs["passifPaiementEditor"].form.resetFields();
      this.hideModal();
      this.stopProcessing();
    },
    handleEdit(value) {
      this.edit_id = value;
      this.fetchPaiementById(this.edit_id)
              .then(() => this.showEditor());
    },
    resetSelectedCotisation() {
      this.clearSelectedCotisation();
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    showModal() {
      this.visible = true;
    },
    hideModal() {
      this.visible = false;
    },
    startPassifPaiementEditorProcessing() {
      this.passifPaiementEditorProcessing = true;
    },
    stopPassifPaiementEditorProcessing() {
      this.passifPaiementEditorProcessing = false;
    },
    handleAdd() {
      if (!this.isUserAccepter(this.user.etat)) {
        this.$_throwAnError("L'administration n'a pas encore accepté votre demande, vous ne pouvez pas ajouter un paiement.");
        return;
      }
      this.showEditor();
    },
    genererateNewPaiementEditorKey() {
      this.cotisationComponentKey = this.getRandomInt();
    },
    handleDelete(value) {
      this.delete(value).then(() => this.getCotisationList());
    },
    handleFileDelete(value) {
      this.deleteFile(value).then(() => this.getCotisationList());
    },
    ...mapActions({
      fetchCotisations: "fetchUserPaiements",
      fetchPaiementById: "fetchUserPaiementById",
      getUser : "fetchAdherent",
      fetchReglements: "fetchUserReglements",
      calculateUserBalance: "calculateUserBalance",
      getTypePaiements: "fetchTypePaiementsWithoutPagination",
      addPaiementPassif: "setUserPaiementPassif",
      add: "storeUserPaiement",
      update: "updateUserPaiement",
      delete: "destroyUserPaiement",
      deleteFile: "destroyUserPaiementFile",
    }),
    ...mapGetters({
      getCotisationReglementById: "getCotisationsById",
      currentExercice: "getCurrentExercice"
    }),
    ...mapMutations({
      resetPagination: "setUserPaiementsDefaultPagination",
      setPagination: "setUserPaiementsPagination",
      setReglementPagination: "setUserReglementPagination",
      clearSelectedCotisation: "clearSelectedPaiement",
      clearUserBalance: "clearUserBalance",
    }),
  },
};
</script>
<style scoped>
.margin_btm {
  margin-bottom: 20px;
}
.mg-b {
  margin-bottom: 3%;
}
.mg-t {
  margin-top: 3%;
}
</style>